// import React, { useState } from "react";
// import $ from "jquery";
import Wrapper from "./style";
import FormModal from "../FormModal";
import PdfModal from "../PdfModal";

const Ribbon = () => {
    return (
        <Wrapper id="ribbon" className="ribbon-body">
            <div className="ribbon">
                <div className="ribbon-container">
                    <div className="container">
                        <span>Training and Placement Batch(24-25)</span>
                        <PdfModal />
                        <FormModal />
                        {/* <button className="btn btn-danger close" onClick={closeRibbon}>
                                x
                            </button> */}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Ribbon;
